// src/lib/stores/formData.ts
import { writable, get } from 'svelte/store';
import { browser } from '$app/environment';
import {
	isIndexedDBSupported,
	hasImage as checkImageExists
} from '$lib/utils/imageService';

// Create a more robust store initialization
function createFormDataStore() {
	// Define default form structure
	const defaultForm = {
		// general personal data
		gender: '',
		first_name: '',
		last_name: '',
		date_of_birth: '',
		// latin
		latinFirstName: '',
		latinLastName: '',

		//nationality
		countryId: '',
		countryCode: '',
		countryName: '',
		nationality: null,

		// id document data
		id_type: 0,
		idLanguageId: '',
		idLanguageCode: '',
		id_language_name: '',

		// address
		street_name: '',
		building_name: '',
		building_number: '',
		flat_number: '',
		zipcode: '',
		city: '',
		state: '',

		//addressCountry
		addressCountryId: '',
		addressCountryName: '',
		addressCountryCode: '',

		//Address proof
		address_proof_issued_by: '',
		address_proof_issued_date: '',
		address_proof_language: '',
		address_proof_language_id: '',
		address_proof_language_code: '',
		address_proof_language_name: '',

		// File IDs for API references
		selfie_file_id: '',
		id_front_file_id: '',
		id_back_file_id: '',
		address_proof_front_file_id: '',
		address_proof_back_file_id: '',

		// Image existence flags (true if image exists in IndexedDB)
		// These are not the actual images but just markers
		selfie_image_exists: false,
		id_front_image_exists: false,
		id_back_image_exists: false,
		address_proof_image_exists: false
	};

	// Only try to load from localStorage on browser
	let initialData = defaultForm;

	if (browser) {
		try {
			const savedData = localStorage.getItem('formData');
			if (savedData) {
				initialData = { ...defaultForm, ...JSON.parse(savedData) };
			}
		} catch (error) {
			console.error('Error loading form data from localStorage:', error);
			// Use the default form if there's an error
		}
	}

	// Create the writable store
	const store = writable(initialData);

	// Add subscribers to save changes
	if (browser) {
		store.subscribe(value => {
			try {
				localStorage.setItem('formData', JSON.stringify(value));
			} catch (error) {
				console.error('Error saving form data to localStorage:', error);
			}
		});
	}

	// Return both the store and custom methods
	return {
		...store,
		// Get current value without subscribing
		getValue: () => get(store),

		// Mark an image as stored (without storing the actual image)
		markImageStored: (key: string, exists: boolean = true) => {
			store.update(form => {
				return { ...form, [`${key}_exists`]: exists };
			});
		},

		// Check if all required data fields are filled
		hasRequiredFields: (fields: string[]) => {
			const data = get(store);
			return fields.every(field => {
				const value = data[field];
				if (typeof value === 'string') {
					return value.trim() !== '';
				} else if (typeof value === 'number') {
					return true;
				} else {
					return !!value;
				}
			});
		},

		// Check if an image is marked as stored
		hasImage: async (key: string) => {
			const data = get(store);
			// First check formData marker
			if (data[`${key}_exists`]) return true;

			// If not found in formData, check IndexedDB directly
			// and update formData if found
			const exists = await checkImageExists(key);
			if (exists) {
				store.update(form => {
					return { ...form, [`${key}_exists`]: true };
				});
			}
			return exists;
		},

		// Reset the store to default values
		reset: () => {
			store.set(defaultForm);
		}
	};
}

// Create and export the enhanced store
export const formData = createFormDataStore();

// Storage status check for UI feedback
export let storageAvailable = writable(true);

// Initialize storage status check
if (browser) {
	isIndexedDBSupported().then(available => {
		storageAvailable.set(available);
	}).catch(() => {
		storageAvailable.set(false);
	});
}