// src/lib/utils/imageService.ts
import { formData } from '$lib/stores/formData';
import { get } from 'svelte/store';
import { browser } from '$app/environment';

/**
 * Consolidated Image Service for ZenPass
 *
 * This service replaces the previous indexedDB.ts utility.
 * All image storage operations should use this service.
 *
 * Features:
 * - IndexedDB storage with localStorage fallback
 * - Integration with Svelte stores for reactive UI updates
 * - Comprehensive error handling
 * - Utilities for checking image existence and retrieving all form images
 */

// Constants for image keys
export const SELFIE_KEY = 'selfie_image';
export const ID_FRONT_KEY = 'id_front_image';
export const ID_BACK_KEY = 'id_back_image';
export const ADDRESS_PROOF_KEY = 'address_proof_image';

// IndexedDB setup
const DB_NAME = 'zenpassImageDB';
const DB_VERSION = 1;
const STORE_NAME = 'images';

// For localStorage fallback, use consistent prefixes
const LS_PREFIX = 'img_';

// Track if IndexedDB is available and working
let isDBAvailable: boolean | null = null;
let dbPromise: Promise<IDBDatabase> | null = null;

/**
 * Check if IndexedDB is supported and working in this browser
 * @returns Promise<boolean>
 */
export async function isIndexedDBSupported(): Promise<boolean> {
	// Return cached result if we've already checked
	if (isDBAvailable !== null) {
		return isDBAvailable;
	}

	// Not in browser environment
	if (!browser) {
		isDBAvailable = false;
		return false;
	}

	// Check basic browser support
	if (!window.indexedDB) {
		console.warn('IndexedDB is not supported by this browser');
		isDBAvailable = false;
		return false;
	}

	// Verify we can actually open a database (some browsers report support but fail)
	try {
		const request = indexedDB.open('testDB');
		return new Promise((resolve) => {
			request.onsuccess = (event) => {
				const db = (event.target as IDBOpenDBRequest).result;
				db.close();
				// Try to delete the test database
				try {
					indexedDB.deleteDatabase('testDB');
				} catch (e) {
					// Ignore deletion errors
				}
				console.log('IndexedDB is supported and working');
				isDBAvailable = true;
				resolve(true);
			};

			request.onerror = () => {
				console.warn('IndexedDB is supported but not working:', request.error);
				isDBAvailable = false;
				resolve(false);
			};
		});
	} catch (error) {
		console.warn('Error testing IndexedDB:', error);
		isDBAvailable = false;
		return false;
	}
}

/**
 * Initialize the database connection
 */
function initDB(): Promise<IDBDatabase> {
	if (!browser) return Promise.reject('Not in browser environment');

	if (dbPromise) return dbPromise;

	dbPromise = new Promise((resolve, reject) => {
		const request = indexedDB.open(DB_NAME, DB_VERSION);

		request.onerror = () => {
			console.error('Error opening IndexedDB:', request.error);
			isDBAvailable = false;
			reject(request.error);
		};

		request.onsuccess = () => resolve(request.result);

		request.onupgradeneeded = (event) => {
			const db = (event.target as IDBOpenDBRequest).result;
			if (!db.objectStoreNames.contains(STORE_NAME)) {
				db.createObjectStore(STORE_NAME);
			}
		};
	});

	return dbPromise;
}

/**
 * Save an image to storage (IndexedDB with localStorage fallback)
 * @param key - The key to store the image under
 * @param imageData - The image data (typically a base64 string)
 * @returns Promise<void>
 */
export async function saveImage(key: string, imageData: string): Promise<void> {
	if (!browser) return Promise.reject('Not in browser environment');

	try {
		// First check if IndexedDB is working
		const dbSupported = await isIndexedDBSupported();

		if (!dbSupported) {
			// Fallback to localStorage
			try {
				// Images can be large, so we need to be careful with localStorage
				const localStorageKey = `${LS_PREFIX}${key}`;
				localStorage.setItem(localStorageKey, imageData);

				// Update formData to mark this image as stored
				formData.update(data => {
					const newData = { ...data };
					newData[`${key}_exists`] = true;
					return newData;
				});

				return;
			} catch (localStorageError) {
				console.error('Failed to save to localStorage fallback:', localStorageError);
				throw new Error('Could not save image - storage not available');
			}
		}

		const db = await initDB();
		return new Promise((resolve, reject) => {
			try {
				const transaction = db.transaction([STORE_NAME], 'readwrite');
				const store = transaction.objectStore(STORE_NAME);

				const request = store.put(imageData, key);

				request.onerror = () => {
					console.error(`Error saving image with key ${key}:`, request.error);
					reject(new Error(`Failed to save image: ${request.error?.message || 'Unknown error'}`));
				};

				request.onsuccess = () => {
					// Update formData to mark this image as stored
					formData.update(data => {
						const newData = { ...data };
						newData[`${key}_exists`] = true;
						return newData;
					});
					resolve();
				};

				transaction.oncomplete = () => {
					// No need to close DB with modern IndexedDB implementations
				};

				transaction.onerror = () => {
					console.error('Transaction error when saving image:', transaction.error);
					reject(new Error(`Transaction failed: ${transaction.error?.message || 'Unknown error'}`));
				};
			} catch (error) {
				console.error(`Error in transaction setup for ${key}:`, error);
				reject(error);
			}
		});
	} catch (error) {
		console.error('Error in saveImage:', error);
		throw error;
	}
}

/**
 * Retrieve an image from storage (IndexedDB with localStorage fallback)
 * @param key - The key the image is stored under
 * @param migrationFlag - If true, will attempt to migrate the image from localStorage
 * @returns Promise<string | null> - The image data or null if not found
 */
export async function getImage(key: string, migrationFlag = false): Promise<string | null> {
	if (!browser) return null;

	try {
		// First check if IndexedDB is working
		const dbSupported = await isIndexedDBSupported();

		if (!dbSupported || migrationFlag) {
			// Try localStorage fallback
			try {
				const formData = localStorage.getItem('formData');

				if (formData) {
					const parsedData = JSON.parse(formData);
					const image = parsedData[key];
					if (image) {
						return image;
					}
				}
			} catch (localStorageError) {
				console.error('Failed to retrieve from localStorage fallback:', localStorageError);
				return null;
			}
		}

		const db = await initDB();
		return new Promise((resolve, reject) => {
			try {
				const transaction = db.transaction([STORE_NAME], 'readonly');
				const store = transaction.objectStore(STORE_NAME);

				const request = store.get(key);

				request.onerror = () => {
					console.error(`Error getting image with key ${key}:`, request.error);
					resolve(null); // Resolve with null instead of rejecting for better UX
				};

				request.onsuccess = (event) => {
					const result = (event.target as IDBRequest).result || null;
					resolve(result);
				};

				transaction.oncomplete = () => {
					// No need to close in modern implementations
				};

				transaction.onerror = () => {
					console.error('Transaction error when getting image:', transaction.error);
					resolve(null);
				};
			} catch (error) {
				console.error(`Error in transaction setup for get ${key}:`, error);
				resolve(null);
			}
		});
	} catch (error) {
		console.error('Error in getImage:', error);
		return null; // Return null instead of throwing to make the function more resilient
	}
}

/**
 * Delete an image from storage
 * @param key - The key of the image to delete
 * @returns Promise<void>
 */
export async function deleteImage(key: string): Promise<void> {
	if (!browser) return;

	// Always try to delete from localStorage fallback
	try {
		const localStorageKey = `${LS_PREFIX}${key}`;
		localStorage.removeItem(localStorageKey);
	} catch (e) {
		// Ignore localStorage errors
	}

	try {
		// Check if IndexedDB is working
		const dbSupported = await isIndexedDBSupported();
		if (!dbSupported) {
			return; // Already tried localStorage deletion
		}

		const db = await initDB();
		return new Promise((resolve, reject) => {
			try {
				const transaction = db.transaction([STORE_NAME], 'readwrite');
				const store = transaction.objectStore(STORE_NAME);

				const request = store.delete(key);

				request.onerror = () => {
					console.error(`Error deleting image with key ${key}:`, request.error);
					reject(new Error('Failed to delete image'));
				};

				request.onsuccess = () => {
					// Update formData to remove the existence marker
					formData.update(data => {
						const newData = { ...data };
						delete newData[`${key}_exists`];
						return newData;
					});
					resolve();
				};

				transaction.oncomplete = () => {
					// No need to close in modern implementations
				};

				transaction.onerror = () => {
					console.error('Transaction error when deleting image:', transaction.error);
					reject(new Error('Transaction failed when deleting image'));
				};
			} catch (error) {
				console.error(`Error in transaction setup for delete ${key}:`, error);
				reject(error);
			}
		});
	} catch (error) {
		console.error('Error in deleteImage:', error);
		throw error;
	}
}

/**
 * Check if an image exists in storage
 * @param key - The key to check
 * @returns Promise<boolean>
 */
export async function hasImage(key: string): Promise<boolean> {
	if (!browser) return false;

	// First check formData for quick reference (memory cache)
	const formDataValue = get(formData)[`${key}_exists`];
	if (formDataValue) {
		const image = await getImage(key);

		// update local storage if image is not found
		if (!image) {
			formData.update(data => {
				const newData = { ...data };
				delete newData[`${key}_exists`];
				return newData;
			});
		}

		return !!formDataValue;
	}

	// If not in formData cache, check storage directly
	try {
		// First check localStorage (faster than IndexedDB)
		try {
			const localStorageKey = `${LS_PREFIX}${key}`;
			const lsValue = localStorage.getItem(localStorageKey);
			if (lsValue) {
				// Update formData cache for future checks
				formData.update(data => {
					const newData = { ...data };
					newData[`${key}_exists`] = true;
					return newData;
				});
				return true;
			}
		} catch (e) {
			// Ignore localStorage errors and continue to IndexedDB
		}

		// Check IndexedDB
		const image = await getImage(key);
		const exists = image !== null;

		// Update formData cache if found
		if (exists) {
			formData.update(data => {
				const newData = { ...data };
				newData[`${key}_exists`] = true;
				return newData;
			});
		}

		return exists;
	} catch (error) {
		console.error(`Error checking if image ${key} exists:`, error);
		return false;
	}
}

/**
 * Get all images needed for form submission
 * This function retrieves all stored images for API submission
 * @returns Promise<{[key: string]: string}> - Object mapping form field names to image data
 */
export async function getFormImages(): Promise<{[key: string]: string}> {
	if (!browser) return {};

	const images: {[key: string]: string} = {};

	const keys = [SELFIE_KEY, ID_FRONT_KEY, ID_BACK_KEY, ADDRESS_PROOF_KEY];

	for (const key of keys) {
		if (await hasImage(key)) {
			const image = await getImage(key);
			if (image) {
				// Convert key name to formData field name
				const formDataKey = keyToFormDataField(key);
				images[formDataKey] = image;
			}
		}
	}

	return images;
}

/**
 * Convert image storage key to formData field name
 * @param key - Storage key
 * @returns Field name in formData
 */
function keyToFormDataField(key: string): string {
	switch(key) {
		case SELFIE_KEY: return 'selfie';
		case ID_FRONT_KEY: return 'id_front';
		case ID_BACK_KEY: return 'id_back';
		case ADDRESS_PROOF_KEY: return 'address_proof_front';
		default: return key;
	}
}